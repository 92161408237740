<template>
  <div>    
    <div class="login d-flex just justify-content-center">
      <form class="login-form w-login-form" v-on:submit.prevent="onLogin">
        <div class="form-group">
          <b-form-input v-model="user.userName" placeholder="Email" disabled></b-form-input>
        </div>
        <div class="mb-1 form-group">
          <b-form-input v-model="user.password" type="password" placeholder="Password" disabled></b-form-input>
        </div>
        <div class="mb-1 text-right form-group">
          <a href="#" disabled>Забыли пароль?</a>
        </div>
        <div class="form-group">
          <b-button variant="success" disabled>Войти</b-button>
        </div>
      </form>
    </div>

    <is-agree-modal :show="true" :user="user" />

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from "vuex";
import { IsAgreeModal } from '@/components';

export default {
  components: { IsAgreeModal },
  name: "CheckAgree",
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      "userRole",
      "isAuth",
      "isAdmin",
      "isLeader",
      "isManager",
      "isCurator",
      "isManufacturer",
      "isDealer"
    ]),
    ...mapState({
      user: state => {
        return {
          userName: state.auth.user.email,
          password: state.auth.secret
        }
      }
    })  
  }

}
</script>

<style lang="scss">
.login {
  &-form {
    position: relative;
    top: 20vh;
  }
}
.w-login-form{
  width: 25%;
  min-width: 15em;
  max-width: 25em;
}
</style>
